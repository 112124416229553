.main-nav {
    margin: 0;
    padding: 0;
    width: 100%;
    list-style: none;
    text-align: left;
    font-size: 16px;
    font-weight: 300;
    overflow-y: auto;
}
.main-nav li {
    display: block;
    position: relative;
    margin-bottom: 10px;
}
.main-nav li a {
    display: inline-block;
}
.main-nav li img {
    display: inline-block;
    max-width: 20px;
    margin-right: 5px;
}
.main-nav li.is-disabled::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    cursor: not-allowed;
}
.main-nav li.is-disabled a {
    color: #666;
}
.main-nav li.is-disabled img {
    -webkit-filter: grayscale(100%);
    -webkit-filter: grayscale(1);
    filter: gray;
    filter: grayscale(100%);
    filter: grayscale(1);
}

.main-nav__step {
    font-weight: 600;
    padding-bottom: 5px;
    border-bottom: 1px solid #444;
}

.main-nav__subnav {
    font-size: 14px;
}

@media (min-width: 768px) {
    .main-nav {
        padding-top: 30px;
        max-width: 500px;
        font-size: 18px;
    }

    .main-nav li img {
        margin-right: 10px;
    }

    .main-nav__subnav {
        font-size: 18px;
    }
}