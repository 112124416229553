.plan-component-wrapper {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
}

.PlanComponent.body {
    padding: 20px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom;
    image-rendering: -webkit-optimize-contrast;
    justify-content: space-between;
}

.PlanComponent.simple{
  justify-content: space-evenly;
}

.plan-bottom {
    margin-bottom: 0;
}

.btn-video {
    padding: 0;
    margin: 0 5px;
    background-color: #252a33;
    border: 1px solid #555;
}
.btn-video:hover, .btn-video:focus {
    color: #fff;
    background-color: #444;
    opacity: 0.90;
}
.btn-video img {
    border-radius: 3px 0 0 3px;
}
.btn-video span {
    display: inline-block;
    padding: 0 15px;
}

.plan-component-wrapper .plan-component-spacer {
    height: 30px;
}

.PlanComponent.crm {
    /*background-image: url(https://d36evp5uoqrlgl.cloudfront.net/mobile-bkg-crm.png);*/
    background-image: url(https://d36evp5uoqrlgl.cloudfront.net/crm-mobile-bg.png);
}

.plan-component-wrapper.crm .plan-component-spacer {
    background: #3370b7;
}

.PlanComponent.me{
    background-image: url(https://d36evp5uoqrlgl.cloudfront.net/me-mobile-bg.png);
}
.plan-component-wrapper.me .plan-component-spacer {
    background: #3370b7;
}

.PlanComponent.creative{
    background-image: url(https://d36evp5uoqrlgl.cloudfront.net/creative-mobile-bg.png);
}
.plan-component-wrapper.creative .plan-component-spacer {
    background: #3370b7;
}

.PlanComponent.uxi{
    background-image: url(https://d36evp5uoqrlgl.cloudfront.net/uxi-mobile-bg.png);
}
.plan-component-wrapper.uxi .plan-component-spacer {
    background: #f6901e;
}

.PlanComponent.em3{
    background-image: url(https://d36evp5uoqrlgl.cloudfront.net/em3-mobile-bg.png);
}
.plan-component-wrapper.em3 .plan-component-spacer {
    background: #2fa8a5;
}

.PlanComponent.sms{
    background-image: url(https://d36evp5uoqrlgl.cloudfront.net/sms-mobile-bg.png);
}
.plan-component-wrapper.sms .plan-component-spacer {
    background: #2fa9a5;
}

.PlanComponent.lla{
    background-image: url(https://d36evp5uoqrlgl.cloudfront.net/lla-mobile-bg.png);
}
.plan-component-wrapper.lla .plan-component-spacer {
    background: #cc3430;
}

.PlanComponent.analytics{
    background-image: url(https://d36evp5uoqrlgl.cloudfront.net/analytics-mobile-bg.png);
}

.plan-component-wrapper.analytics .plan-component-spacer {
    background: #ff7e00;
}

.PlanComponent.trl{
    background-image: url(https://d36evp5uoqrlgl.cloudfront.net/trl-mobile-bg.png);
}

.plan-component-wrapper.trl .plan-component-spacer {
    background: #0b4063;
}

.PlanComponent.nla{
    background-image: url(https://d36evp5uoqrlgl.cloudfront.net/nla-mobile-bg.png);
}

.plan-component-wrapper.nla .plan-component-spacer {
    background: #71ce45;
}

.PlanComponent.smm{
    background-image: url(https://d36evp5uoqrlgl.cloudfront.net/smm-mobile-bg.png);
}

.plan-component-wrapper.smm .plan-component-spacer {
    background: #0cb186;
}

.PlanComponent.sta{
    background-image: url(https://d36evp5uoqrlgl.cloudfront.net/sta-mobile-bg.png);
}

.plan-component-wrapper.sta .plan-component-spacer {
    background: #6a479a;
}

.PlanComponent.tpa{
    background-image: url(https://d36evp5uoqrlgl.cloudfront.net/tpa-mobile-bg.png);
}

.plan-component-wrapper.tpa .plan-component-spacer {
    background: #03adee;
}

.PlanComponent.ret{
    background-image: url(https://d36evp5uoqrlgl.cloudfront.net/ret-moble-bg.png);
}

.plan-component-wrapper.ret .plan-component-spacer {
    background: #ee5c2b;
}


.plan-badge.crm {
    background-image: url(https://d36evp5uoqrlgl.cloudfront.net/crm-badge.png);
}
.plan-badge.me {
    background-image: url(https://d36evp5uoqrlgl.cloudfront.net/me-badge.png);
}
.plan-badge.creative {
    background-image: url(https://d36evp5uoqrlgl.cloudfront.net/creative-badge.png);
}
.plan-badge.uxi {
    background-image: url(https://d36evp5uoqrlgl.cloudfront.net/uxi-badge.png);
}
.plan-badge.em3 {
    background-image: url(https://d36evp5uoqrlgl.cloudfront.net/em3-badge.png);
}
.plan-badge.sms {
    background-image: url(https://d36evp5uoqrlgl.cloudfront.net/sms-badge.png);
}
.plan-badge.lla {
    background-image: url(https://d36evp5uoqrlgl.cloudfront.net/lla-badge.png);
}
.plan-badge.analytics {
    background-image: url(https://d36evp5uoqrlgl.cloudfront.net/analytics-badge.png);
}
.plan-badge.trl {
    background-image: url(https://d36evp5uoqrlgl.cloudfront.net/trl-badge.png);
}
.plan-badge.nla {
    background-image: url(https://d36evp5uoqrlgl.cloudfront.net/nla-badge.png);
}
.plan-badge.smm {
    background-image: url(https://d36evp5uoqrlgl.cloudfront.net/smm-badge.png);
}
.plan-badge.sta {
    background-image: url(https://d36evp5uoqrlgl.cloudfront.net/sta-badge.png);
}
.plan-badge.tpa {
    background-image: url(https://d36evp5uoqrlgl.cloudfront.net/tpa-badge.png);
}
.plan-badge.ret {
    background-image: url(https://d36evp5uoqrlgl.cloudfront.net/ret-badge.png);
}



.overview-icon, .how-icon {
    max-width: 45px;
}

.feature-icon{
    max-width: 18px;
    position: relative;
    top: -2px;
    left: -1px;
}

#plan-component .btn-primary,
#plan-component .btn-secondary{
    font-size: 16px;
}

.plan-badge {
    min-height: 100px;
    min-width: 100%;
    background-repeat: no-repeat;
    background-position: left;
    background-size: contain;
}

@media screen and (min-width: 375px){
    .plan-badge {
        min-height: 150px;
    }
}

/*@media screen and (min-width: 414px){*/
    /*.plan-badge {*/
        /*min-height: 150px;*/
    /*}*/
/*}*/


@media screen and (min-width: 768px){

    .plan-badge {
        background-position: center;
    }

    /* .PlanComponent.body {
        justify-content: inherit;
    } */

    .PlanComponent .plan-top {
        position: relative;
        margin-top: 0;
        max-width: 800px;
        z-index: 1;
    }

    .PlanComponent.body {
        background-size: inherit;
    }

    #plan-component .btn-primary, #plan-component .btn-secondary {
        margin: 0 10px;
        font-size: 18px;
    }

    .PlanComponent.component .plan-bottom {
        position: relative;
        z-index: 1;
    }
    .plan-component-wrapper .plan-component-spacer {
        height: 0;
    }

    .btn-video {
        font-size: 18px;
        padding: 0;
        margin: 0 15px;
    }
    .btn-video span {
        padding: 0 20px;
    }

    .overview-icon, .how-icon {
        max-width: 65px;
    }

    .plan-badge {
        display: block;
        width: 550px;
        height: 391px;
        margin: -70px 0 -150px;
    }

    .plan-badge.crm {
        background-image: url(https://d36evp5uoqrlgl.cloudfront.net/badge-crm.png);
    }
    .plan-badge.me {
        background-image: url(https://d36evp5uoqrlgl.cloudfront.net/badge-me.png);
    }
    .plan-badge.creative {
        background-image: url(https://d36evp5uoqrlgl.cloudfront.net/badge-creative.png);
    }
    .plan-badge.uxi {
        background-image: url(https://d36evp5uoqrlgl.cloudfront.net/badge-uxi.png);
    }
    .plan-badge.em3 {
        background-image: url(https://d36evp5uoqrlgl.cloudfront.net/badge-em3.png);
    }
    .plan-badge.sms {
        background-image: url(https://d36evp5uoqrlgl.cloudfront.net/badge-sms.png);
    }
    .plan-badge.lla {
        background-image: url(https://d36evp5uoqrlgl.cloudfront.net/badge-lla.png);
    }
    .plan-badge.analytics {
        background-image: url(https://d36evp5uoqrlgl.cloudfront.net/badge-analytics.png);
    }
    .plan-badge.trl {
        background-image: url(https://d36evp5uoqrlgl.cloudfront.net/badge-trl.png);
    }
    .plan-badge.nla {
        background-image: url(https://d36evp5uoqrlgl.cloudfront.net/badge-nla.png);
    }
    .plan-badge.smm {
        background-image: url(https://d36evp5uoqrlgl.cloudfront.net/badge-smm.png);
    }
    .plan-badge.sta {
        background-image: url(https://d36evp5uoqrlgl.cloudfront.net/badge-sta.png);
    }
    .plan-badge.tpa {
        background-image: url(https://d36evp5uoqrlgl.cloudfront.net/badge-tpa.png);
    }
    .plan-badge.ret {
        background-image: url(https://d36evp5uoqrlgl.cloudfront.net/badge-ret.png);
    }

    .PlanComponent.crm {
        background-image: url(https://d36evp5uoqrlgl.cloudfront.net/desktop-bkg-crm.png);
    }

    .PlanComponent.me {
        background-image: url(https://d36evp5uoqrlgl.cloudfront.net/desktop-bkg-me.png);
    }

    .PlanComponent.creative{
        background-image: url(https://d36evp5uoqrlgl.cloudfront.net/desktop-bkg-creative.png);
    }

    .PlanComponent.uxi{
        background-image: url(https://d36evp5uoqrlgl.cloudfront.net/desktop-bkg-website.png);
    }
    .PlanComponent.em3{
        background-image: url(https://d36evp5uoqrlgl.cloudfront.net/desktop-bkg-em3.png);
    }
    .PlanComponent.sms{
        background-image: url(https://d36evp5uoqrlgl.cloudfront.net/desktop-bkg-sms.png);
    }
    .PlanComponent.lla{
        background-image: url(https://d36evp5uoqrlgl.cloudfront.net/desktop-bkg-lla.png);
    }
    .PlanComponent.analytics{
        background-image: url(https://d36evp5uoqrlgl.cloudfront.net/desktop-bkg-analytics.png);
    }
    .PlanComponent.trl{
        background-image: url(https://d36evp5uoqrlgl.cloudfront.net/desktop-bkg-trl.png);
    }
    .PlanComponent.nla{
        background-image: url(https://d36evp5uoqrlgl.cloudfront.net/desktop-bkg-nla.png);
    }
    .PlanComponent.smm{
        background-image: url(https://d36evp5uoqrlgl.cloudfront.net/desktop-bkg-smm.png);
    }
    .PlanComponent.sta{
        background-image: url(https://d36evp5uoqrlgl.cloudfront.net/desktop-bkg-sta.png);
    }
    .PlanComponent.tpa{
        background-image: url(https://d36evp5uoqrlgl.cloudfront.net/desktop-bkg-tpa.png);
    }
    .PlanComponent.ret{
        background-image: url(https://d36evp5uoqrlgl.cloudfront.net/desktop-bkg-ret.png);
    }

}

@media screen and (min-width: 1600px){
    .PlanComponent .plan-top {
        margin-top: 20px;
    }

    .plan-badge {
        margin: -40px 0 -80px;
    }
}

@media screen and (max-width: 353px) {

    .PlanComponent .btn-video {
        min-width: 150px;
        margin-top: 5px;
    }

    #plan-component > div.plan-bottom > div.vid-row > button:nth-child(1) > span {
        min-width: 102px;
    }

}
