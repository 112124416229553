.login-form input{
    width: 70%;
    float: left;
}

.login-form button{
    width: 30%;
}

.login-group-dash {
    display: inline-block;
    font-size: 20px;
    margin-right: 10px;
}

.login-group .form-control {
    display: inline-block;
    width: 80px;
    height: 40px;
    margin-right: 10px;
    font-size: 16px;
}

.login-group .btn {
    position: relative;
    top: -2px;
    margin-left: 10px;
    padding: 8px 12px;
}

@media only screen and (max-width: 320px)  {

    .login-group .btn {
        position: relative;
        top: 13px;
        margin: 0px;
    }



}
