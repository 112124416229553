.HeaderInit{
	text-align: center;
    padding: 5px;
	background-color: #fc7713;
	color: #fff;
}

#header-title{
	margin: 0px;
	padding: 5px;
	font-size: 20px;
	font-weight: 600;
}

.header-text{
	font-style: italic;
	font-size: 18px;
}

