.credit-text{
	display: block;
	color: grey;
}

#PlanRequest{
	padding: 20px;
}

@media (min-width: 768px) {
    #PlanRequest{
        padding: 20px 0 0;
    }
}