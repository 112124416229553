.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    line-height: 1.3;
}

.plans-pricing{
    min-height: 100vh;
    background-color: #252933;
    color: #fff;
    font-family: 'Source Sans Pro', sans-serif;
}

.page-with-header{
    display: flex;
    flex-flow: column;
    /*height: 100vh;*/
    min-height: 100vh;
}

.page-with-header .header {
    flex: 0 1 auto;
    padding-top: 5px;
    /* The above is shorthand for:
    flex-grow: 0,
    flex-shrink: 1,
    flex-basis: auto
    */
}

.main-layout-component .btn-primary{
    min-width: 100px;
}

.page-with-header .body{
    flex: 1 1 auto;
    text-align: center;
    display: flex;
    display: -ms-flexbox;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 5px 15px;
}

.full-body {
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 20px;
}

#simple-component{
    justify-content: space-evenly;
}

/*Form Styles*/
.simple-form{
    width:100%;
}

label{
    text-align: left;
    width: 100%;
    font-weight: 500;
}

.form-check-label {
    display: flex;
    align-items: center;
    justify-content: baseline;
}

.form-check-label input{
    width: auto;
}


/* Links */

.text-link{
    text-decoration: underline;
    padding: 0px;
    border: none;
    background-color: transparent;
}

.text-link:hover, .text-link:active, .text-link:visited{
    text-decoration: underline;
    color: #fc7713;
    cursor: pointer;
}

.link{
    color: white;
}

.link:hover{
    color: #fc7713;
}

/* Buttons */

.next-btn {
    width: 110px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: space-evenly;
    margin: 0 auto;
}

.next-btn .fa{
    font-size: 18px;
    margin-left: 5px;
}

.btn-row .btn{
    margin: 0px 5px;
}

.btn-primary{
    font-size: 18px;
}

.btn-secondary{
    color: #fff;
    background-color: #656d7c;
}
.btn-secondary:hover, .btn-secondary:focus {
    color: #fff;
    background-color: #f4881e;
}

.btn-hollow, .btn-hollow:visited, .btn-hollow:active, .btn-hollow:focus, .btn-hollow:hover{
    background-color: transparent;
    border: 1px solid #5e6575;
    color: #5e6575;
}

.vertical-buttons{
    clear: both;
}

.btn-link-white {
    color: #fff;
}
.btn-link-white:hover, .btn-link-white:focus {
    color: #2b90ce;
    text-decoration: none;
}

.text-white{
    color: #ffffff !important;
}

.text-white:hover{
    color: #fc7713 !important;
}

/* Title */

.title{
    font-size: 30px;
    margin-top: 0px;
}
.title::after{
    content: '';
    display: block;
    height: 5px;
    width: 50px;
    margin: 10px auto 0;
    background-color: #2b90ce;
}
.title-blank {
    font-size: 24px;
}
.title-blank::after {
    display: none;
}

.full-body .title{
    /*margin-top: 150px;*/
}

.info{
    padding: 20px 0px;
    font-size: 16px;
    text-align: center;
    font-weight: 100;
}

.more-info{
    padding: 20px 20px;
    font-size: 14px;
    text-align: center;
    font-weight: 100;
}

.bottom-link{
    font-weight: 100;
}

.no-underline{
    text-decoration: none;
}

.box{
    background-color: #252933;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.button-row {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
}

.box-color{
    background-color: #20232b;
    padding: 15px 20px;
    text-align: center;
    width: 100%;
}

.product-box{
    background-color: #20232b;
    width: 100%;
    padding: 10px 20px;
}

.product-icon {
    position: relative;
    max-width: 35px;
    top: -3px;
}

.btn-box{
    display: block;
    width: 20px;
    height: 20px;
}

.check-btn {
    border: none;
    padding: 0;
    width: 20px;
    height: 20px;
    line-height: 1;
}
.check-btn .fa {
    font-size: 14px;
}

.in-btn{
    background-color: #646d7c;
}

.out-btn{
    background-color: #14b668;
}

/* Content */

.line1{
    text-transform: uppercase;
    font-style: italic;
    font-weight: 300;
}
.line2 {
    font-size: 16px;
    margin: 15px 0 20px;
}
.line3 {
    margin-bottom: 40px;
    font-size: 18px;
}

.description{
    color: #e8e5e5;
    font-weight: 300;
    font-size: 15px;
}

/*Forms*/
input.form-control.invalid{
    border: 1px solid red;
}

input.form-control.invalid:focus{
    border: 1px solid #ccc !important;
}


input.form-control.invalid:focus + .text-danger{
    display: none;
}

/*Modals*/
.modal-overlay{
    position: fixed;
    z-index: 2;
    top:0;
    bottom:0;
    left:0;
    right:0;
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    background-color: #1d2028;
    background-color: rgba(29,32,40,.99);
}

.modal-content {
    padding: 20px;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    overflow: scroll;
}

a#foo {
    word-wrap: break-word;
}

/* Video Modal */
.modal-video-overlay{
    display: flex;
    background: #1d2028;
    justify-content: center;
    align-items: center;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    -ms-flex-align: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 3;
}

.modal-video-content {
    max-width: 1100px;
    width: 100%;
    padding: 0 12px;
}
.modal-video {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    width: 100%;
    overflow: hidden;
    background: gray;
}
.modal-video iframe, .modal-video object, .modal-video embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.modal-info{
    font-size: 18px;
}

.modal-phone{
    margin: 10px 0px;
    font-size: 20px;
    color: #337ab7;
}

.modal-content .btn-primary, .modal-content .btn-secondary{
    font-size: 14px;
}

.modal-header {
    padding: 0px;
    border-bottom: none;
    float: right;
    display: block;
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 10;
}
.modal-header .fa {
    font-size: 20px;
    cursor: pointer;
    color: #f4f4f4;
}

.modal-box{
    background-color: #252a33;
    padding: 10px 20px;
    text-align: center;
    width: 100%;
    max-width: 800px;
}

.modal-desc{
    font-weight:100;
    font-style: italic;
}

.modal-title{
    font-size: 18px;
}

.modal-header{
 margin-bottom: 5px;
}

.fa-time{
    color: #fff;
    font-weight: 100;
}

.time-list{
    list-style: none;
    padding: 0px;
}

.inline-group{

}

.inline-group input{
    width: 80%;
    float: left;
}

.inline-group button{
    width: 20%;
}

.marketing-goals-input-field{
    text-align: left;
}

.toggle-marketing-goals{
    color: #555;
}

.form-control{
    border-radius: 0px;
}

.accent-color{
    color: #337ab7;
}

.accent-color-secondary{
    color: #14b668;
}

/* Flexbox */
.flex {
    display: flex;
}
.align-items-center {
    align-items: center !important;
}
.justify-content-center {
    justify-content: center !important;
}
.justify-content-start {
    justify-content: flex-start !important;
}

/* Utilities */

.color-success {
    color: #14b668;
}

.error-message {
    color: red;
}

.font-md {
    font-size: 16px;
}

.font-italic {
    font-style: italic;
}

.flip-horizontal {
    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    filter: FlipH;
    -ms-filter: "FlipH";
}

.img-responsive {
    display: inline-block;
}

.link-white {
    color: #fff;
}
.link-white:hover, .link-white:focus {
    color: #2b90ce;
    text-decoration: none;
}

.hover-fade:hover {
    opacity: 0.80;
}

.left10{
    margin-left: 10px;
}

.top0{
    margin-top: 0;
}
.top10{
    margin-top: 10px;
}
.top20{
    margin-top: 20px;
}
.top30{
    margin-top: 30px;
}
.top40{
    margin-top: 40px;
}
.top50{
    margin-top: 50px;
}
.top60{
    margin-top: 60px;
}
.bottom0{
    margin-bottom: 0px;
}
.bottom10{
    margin-bottom: 10px;
}
.bottom20{
    margin-bottom: 20px;
}
.bottom30 {
    margin-bottom: 30px;
}
.bottom40{
    margin-bottom: 40px;
}
.bottom50{
    margin-bottom: 50px;
}
.bottom60{
    margin-bottom: 60px;
}
.bottom100{
    margin-bottom: 100px;
}

.m-auto{
    margin: auto;
}

.padding-top100{
    padding-top: 100px;
}

.no-padding{
    padding: 0px;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

@media screen and (max-width: 767px) {
    .h4-mobile {
        font-size: 18px;
    }

}

@media screen and (min-width: 768px){
    body {
        font-size: 18px;
    }

    .text-md {
        font-size: 16px;
    }
    .text-sm {
        font-size: 14px;
    }

    .container-form {
        max-width: 500px;
        margin: 0 auto;
    }

    .title {
        font-size: 36px;
    }

    .info {
        font-size: 18px;
    }

    .btn {
        font-size: 16px;
        padding: 10px 18px;
    }
    .btn.btn-sm {
        font-size: 14px;
        padding: 6px 12px;
    }

    .description {
        margin-bottom: 30px;
        font-size: 18px;
    }

    .line1, .line2 {
        font-size: 20px;
        margin-bottom: 30px;
    }
    .line3 {
        font-size: 24px;
    }

    .modal-header {
        top: 15px;
        right: 15px;
    }
    .modal-header .fa {
        font-size: 28px;
        cursor: pointer;
    }

    .modal-content {
        overflow: hidden;
    }

}
