.mc-profile{
    max-width: 100%;
    color: white;
}

.contact-link{
    font-size: 20px;
}

.contact-link:hover{
    text-decoration: none;
    color: whitesmoke;
}

@media (max-width: 375px){
    .mc-image {
        max-width: 140px;
    }

    .mc-header{
        font-size: 20px;
    }
}