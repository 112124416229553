.modal-plan .modal-content {
    padding: 0;
}

.modal-plan .plan-component-wrapper {
    background-color: #20232b;
}

.modal-plan .modal-close {
    display: block;
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 25px;
}

.modal-plan-container {
    position: relative;
}

@media screen and (max-width: 767px){
    .modal-plan-container {
        display: flex;
        flex: 1 1 auto;
    }
}

@media screen and (min-width: 768px){
    .modal-plan .plan-component-wrapper {
        flex: inherit;
        background-color: #20232b;
    }
    .modal-plan .modal-close {
        top: -20px;
        right: -20px;
        font-size: 40px;
    }
}