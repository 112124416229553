.mc-image {
    border-radius: 50%;
    border: 4px solid #ff910f;
}
.mc-profile{
  padding: 10px 20px;
  text-align: center;
  width: 100%;
  max-width: 800px;
  margin-top: 20px;
}

.phone-link{
  font-size: 20px
}
