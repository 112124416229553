.container-letter {
    padding: 0 15px;
}

.LetterComponent.component {
    padding: 0px 0px 10px;
    background-color: #f4f4f4;
    color: #292929;
}

.letter {
    padding: 30px;
    -webkit-box-shadow: 0 0 30px rgba(0,0,0,0.4);
    box-shadow: 0 0 30px rgba(0,0,0,0.4);
    margin-top: -200px;
    background-color: white;
}

.letter a {
    cursor: pointer;
}


.letter .letter__title {
    margin-top: 60px;
    color: #337ab7;
    font-size: 18px;
    font-weight: 600;
}

.letter .letter__badge {
    position: relative;
    top: -2px;
    display: inline-block;
    padding: 1px 8px;
    margin-left: 10px;
    border: 1px solid #ff8103;
    border-radius: 6px;
    color: #ff8103;
    font-size: 14px;
    font-weight: 400;
}
.letter__orange {
    display: inline-block;
    margin-left: 10px;
    color: #ff8103;
    font-size: 18px;
    font-weight: 400;
}

span.letter__signature img {
    max-width: 300px;
}

.letter__list {
    margin: 20px 0;
    padding-left: 20px;
    list-style: none;
}
.letter__list__ca {
    list-style: disc;
}
.letter__list__usa {
    list-style: disc;
}
.letter__list > li {
    position: relative;
    margin-top: 10px;
    margin-bottom: 20px;
}
.bottom20 {
    margin-bottom: 20px;
}
.top30{
    margin-top: 30px;
}
.letter-icon {
    position: absolute;
    top: -7px;
    left: -35px;
    width: 35px;
    height: 36px;
    background-image: url('https://d36evp5uoqrlgl.cloudfront.net/program-icons.png');
    background-repeat: no-repeat;
    transform: scale(0.8);
}
.letter-icon--me {
    background-position: -35px 0;
}
.letter-icon--creative {
    background-position: -70px 0;
}
.letter-icon--crm {
    background-position: -105px 0;
}
.letter-icon--payments {
    background-position: -140px 0;
}
.letter-icon--website {
    background-position: -175px 0;
}
.letter-icon--em3 {
    background-position: -210px 0;
}
.letter-icon--sms {
    background-position: -245px 0;
}
.letter-icon--lla {
    background-position: -280px 0;
}
.letter-icon--trl {
    background-position: -315px 0;
}
.letter-icon--rep {
    background-position: -350px 0;
}
.letter-icon--nla {
    background-position: -385px 0;
}
.letter-icon--ret {
    background-position: -420px 0;
}
.letter-icon--tpa {
    background-position: -455px 0;
}
.letter-icon--sta {
    background-position: -490px 0;
}
.letter-icon--smm {
    background-position: -525px 0;
}
.letter-icon--analytics {
    background-position: -560px 0;
}
.letter-icon--analytics {
    background-position: -560px 0;
}

.letter__signature {
    font-family: 'Pacifico', cursive;
    font-size: 26px;
}

.letter-awards hr {
    border-color: #aaa;
}

.letter-section-jumbo{
    background-color: #252933;
    min-height: 900px;
}

.body.short .letter-section-jumbo{
    min-height: 600px;
}

.LetterHeader #PhoneModalComponent .mc-image,
.LetterHeader #PhoneModalComponent h3,
.LetterHeader #PhoneModalComponent h4{
    display: none;
}

.LetterHeader #PhoneModalComponent .mc-profile p{
    font-size: 20px;
}

@media (min-width: 768px) {
    .container-letter {
        max-width: 800px;
        margin: 0 auto;
        padding: 0;
    }

    .LetterComponent h3 {
        font-size: 28px;
    }

    .letter {
        padding: 80px;
    }
    .letter .letter__title {
        font-size: 20px;
    }
    .letter__list {
        padding-left: 35px;
    }
    .letter__signature {
        font-size: 36px;
    }

    .letter-icon {
        top: -5px;
        left: -43px;
        width: 35px;
        height: 36px;
        transform: scale(1);
    }

    .letter-awards {
        text-align: left;
    }
    .letter-awards-title {
        font-size: 20px;
    }
}
.ca-display-none.CaNoJumbo .letter-section-jumbo { display: none;}
.container-letter.ca.ca.text {margin-top: 30%;}
@media (max-width: 768px) { .container-letter.ca.ca.text {margin-top: 50%;} }