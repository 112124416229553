.LetterHeader .App-logo{
    max-width: 150px;
    padding: 5px;
    display: block;
    margin: 0 auto;
}
.shareButtons {
    margin-top: 15px;
    float: right;
}

.callLink {
    margin-top: 14px;
    display: inline-block;
    cursor: pointer;
}

.margin-bottom-0{
    margin-bottom: 0px;
}

.navbar-dark .fa{
    color: white;
}

.shareButtons a{
    color: white;
    margin-right: 5px;
}

.shareButtons a:hover{
    color: white;
    text-decoration: none;
    cursor: pointer;
}

.navbar-image{
    padding: 5px;
}

div#PhoneModalComponent {
    color: white;
}

.timeZone{
    font-size: 14px;
    color: grey;
    margin-bottom: 20px;
    display: inline-block;
}

.header-mc-image {
    border-radius: 50%;
    border: 1px solid #ff910f;
    max-width: 40px;
}

.header-mc-text {
    vertical-align: middle;
    padding: 0px 2px;
    max-width: 85px;
    word-wrap: break-word;
    display: inline-block;
}

.bread-crumb {
    background-color: #20232b;
    padding: 7px 5px;
    align-items: center;
    display: flex;
    width: 100%;
    justify-content: space-between;
    color: #ccc;
}

.bread-crumb h5{
    margin: 0px;
    font-weight: 300;
    font-size: 13px;
}

.progressbar-container{
    background-color: #000;
}

/*.navbar-header {
    background-color: #262b34;
}*/

.LetterHeader {
    background-color: #1a1e24;
}

.Header li a{
    color: #fff;
}

.nav .open>a, .nav .open>a:focus, .nav .open>a:hover {
    background-color: #20232b;
    border-color: #337ab7;
}

.nav>li>a:focus, .nav>li>a:hover {
    text-decoration: none;
    background-color: #262b34;
}

.navbar-toggle {
    margin: 0;
    min-height: 45px;
}

.float-left{
    float: left;
}

.fa-bars, .fa-angle-left{
    font-size: 20px;
}

.fa-phone {
    font-size: 25px;
}

.navbar {
    margin-bottom: 0px;
    border: none;
}

.back-button {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    /* align-items: center; */
    -ms-flex-pack: space-evenly;
    justify-content: space-around;
    float: right;
    margin-top: 8px;
    margin-right: 10px;
    padding: 2px 10px;
    width: 75px;
}
.back-button:hover, .back-button:focus {
    color: #fff;
    border-color: #fff;
}

.progressbar-container {
    background-color: #1d4466;
    width: 30%;
}

.example-enter {
    opacity: 0.01;
}

.example-enter.example-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
}

.example-leave {
    opacity: 1;
}

.example-leave.example-leave-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
}

@media (min-width: 768px) {
    .navbar-header {
        float: none;
        padding: 0 0 5px;
    }
    .navbar-toggle {
        display: inline-block;
        margin-top: 9px;
    }
    .navbar-toggle .fa {
        font-size: 26px;
    }

    .LetterHeader .App-logo {
        max-width: 220px;
        padding: 10px;
    }

    .bread-crumb {
        padding: 8px 20px;
    }

    .bread-crumb h5 {
        font-size: 15px;
    }

    .back-button {
        margin-top: 18px;
    }

    .header-mc-text {
        padding: 0px 10px;
        max-width: 124px;
    }

    .callLink {
        margin-top: 26px;
    }

    .shareButtons {
        margin-top: 22px;
    }

}

@media (max-width: 420px) {
    .shareButtons {
        margin-top: 14px;
        float: right;
    }
    .LetterHeader .App-logo {
        float: right;
    }
}

@media (max-width: 320px) {
    .LetterHeader .navbar .logo-custom-size{
        width: 50%;
    }
    .LetterHeader .navbar .buttons-custom-size{
        width: 33.33333333%;
    }

}